<template>
  <base-page
      :title="$t('sensorDeviceManage.title')"
      @functionMethod="functionMethod"
      @search="search"
  >
    <table-list
        ref="formList"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.sensorFindList"
        :columns="columns"
        :defaultParams="defaultParams"
        v-model="tableRow"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime) }}
      </template>
      <!-- 规则 -->
      <template v-slot:communicationMode="{ row }">
        {{ $t('communicationModeEnum.' + row.communicationMode) }}
      </template>
      <template v-slot:imgFile="{ row }">
        <el-image v-if="row.imgFile" :src="row.imgFile.fileHost + '/' + row.imgFile.objectName"  />
      </template>
    </table-list>
    <detail v-if="showDetail"
            :tableRow="tableRow"
            @closePage="closePage"
    />
  </base-page>
</template>

<script>
import Detail from './detail'
export default {
  components: {
    Detail
  },

  data() {
    return {
      formData: {},
      defaultParams: {},
      tableRow: null,
      showDetail: false,
      columns: [
        {
          label: this.$t('sensorDeviceManage.name'),
          prop: 'name',
        },
        {
          label: this.$t('sensorDeviceManage.deviceMode'),
          prop: 'deviceMode',
        },
        {
          label: this.$t('sensorDeviceManage.measuringUnit'),
          prop: 'measuringUnit',
        },
        {
          label: this.$t('sensorDeviceManage.communicationMode'),
          prop: 'communicationMode',
        },
        {
          label: this.$t('sensorDeviceManage.maxMeasuringRange'),
          prop: 'maxMeasuringRange',
        },
        {
          label: this.$t('sensorDeviceManage.minMeasuringRange'),
          prop: 'minMeasuringRange',
        },
        {
          label: this.$t('sensorDeviceManage.tolerance'),
          prop: 'tolerance',
        },
        {
          label: this.$t('sensorDeviceManage.imgFile'),
          prop: 'imgFile',
        },
        {
          label: this.$t('sensorDeviceManage.functionDes'),
          prop: 'functionDes',
        },
        {
          label: this.$t('base.createTime'),
          prop: 'createTime',
        },
      ],
    }
  },
  methods: {
    functionMethod(item) {
      console.log(item)
      const { method } = item
      if (method !== 'add' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      // if (
      //     method === 'add' &&
      //     this.tableRow
      // ) {
      //   this.$element.showMsg(this.$t('base.canAddFirstCate'), 'warning')
      //   return
      // }
      switch (method) {
        case 'add':
        case 'edit':
        case 'view':
          this.showDetail = true
          break
        case 'del':
          this.deleteMethod()
          break
        default: // do something
      }
    },
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    search(isActiveQuery) {
      const formData = this.$util.copyData(this.formData)
      this.$refs.formList.searchList(formData, isActiveQuery)
    },
    deleteMethod() {
      this.$element
          .showConfirm({
            content: this.$t('base.deleteConfirm'),
          })
          .then(() => {
            this.$ajax({
              url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.sensorDel,
              data: {id:this.tableRow.id},
            }).then(() => {
              // lang:删除成功
              this.$element.showMsg(this.$t('base.deleteSuccess'), 'success')
              this.search()
            })
          })
    },
  }
}
</script>

<style scoped>

</style>
